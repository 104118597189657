const country = process.env.NEXT_PUBLIC_SITE_COUNTRY || 'nl';

const menus = {
    "nl": {
        "consumer": {
            "main-menu-left": {
                items: [
                    {
                        title: "Energie",
                        url: "/groene-energie",
                        target: "",
                    },
                    {
                        title: "Energieproducten",
                        url: "#",
                        target: "",
                        subItems: [
                            {
                                title: "Thuisbatterij",
                                url: "/thuisbatterij",
                                target: "",
                                iconSrc: "images/menu/thuisbatterij.png",
                            },
                            {
                                title: "Zonnepanelen",
                                url: "/zonnepanelen",
                                target: "",
                                iconSrc: "images/menu/zonnepanelen.png",
                            },
                            {
                                title: "Warmtepomp",
                                url: "/warmtepomp",
                                target: "",
                                iconSrc: "images/menu/warmtepomp.png",
                            },
                            {
                                title: "Airco",
                                url: "/airco",
                                target: "",
                                iconSrc: "images/menu/airco.png",
                            },
                            {
                                title: "Laadpaal",
                                url: "/laadpalen",
                                target: "",
                                iconSrc: "images/menu/laadpaal.png",
                            },
                        ],
                    },
                    {
                        title: "Impact",
                        url: "/impact",
                        target: "",
                    },
                ],
            },
            "main-menu-right": {
                items: [
                    {
                        title: "Over ons",
                        url: "/over-ons",
                        target: "",
                    },
                    {
                        title: "Vragen",
                        url: "/vraag-antwoord",
                        target: "",
                    },
                ],
            },
            "mobile-menu": {
                items: [
                    {
                        title: "Energieproducten",
                        url: "#",
                        target: "",
                        subItems: [
                            {
                                title: "Thuisbatterij",
                                url: "/thuisbatterij",
                                target: "",
                                iconSrc: "images/menu/thuisbatterij.png",
                            },
                            {
                                title: "Zonnepanelen",
                                url: "/zonnepanelen",
                                target: "",
                                iconSrc: "images/menu/zonnepanelen.png",
                            },
                            {
                                title: "Warmtepomp",
                                url: "/warmtepomp",
                                target: "",
                                iconSrc: "images/menu/warmtepomp.png",
                            },
                            {
                                title: "Airco",
                                url: "/airco",
                                target: "",
                                iconSrc: "images/menu/airco.png",
                            },
                            {
                                title: "Laadpaal",
                                url: "/laadpalen",
                                target: "",
                                iconSrc: "images/menu/laadpaal.png",
                            },
                        ],
                    },
                    {
                        title: "Energie",
                        url: "/groene-energie",
                        target: "",
                    },
                    {
                        title: "Impact",
                        url: "/impact",
                        target: "",
                    },
                    {
                        title: "Over ons",
                        url: "/over-ons",
                        target: "",
                    },
                    {
                        title: "Blog",
                        url: "/blog",
                        target: "",
                    },
                    {
                        title: "Vragen",
                        url: "/vraag-antwoord",
                        target: "",
                    },
                    {
                        title: "Contact",
                        url: "/contact",
                        target: "",
                    },
                ],
            }
        },
        "business": {
            "main-menu-left": {
                items: [
                    {
                        title: "Energie",
                        url: "/zakelijk/energie",
                        target: "",
                    },
                    {
                        title: "Zonnepanelen",
                        url: "/zakelijk/zonnepanelen",
                        target: "",
                    },
                    {
                        title: "Energieopslag",
                        url: "/zakelijk/energieopslag",
                        target: "",
                    },
                ],
            },
            "main-menu-right": {
                items: [],
            },
            "mobile-menu": {
                items: [
                    {
                        title: "Energie",
                        url: "/zakelijk/energie",
                        target: "",
                    },
                    {
                        title: "Zonnepanelen",
                        url: "/zakelijk/zonnepanelen",
                        target: "",
                    },
                    {
                        title: "Energieopslag",
                        url: "/zakelijk/energieopslag",
                        target: "",
                    },
                ],
            },
        }
    },
    "za": {
        "consumer": {
            "main-menu-left": {
                items: [
                    {
                        title: "Solar Panels",
                        url: "/solar-panels",
                        target: "",
                    },
                    {
                        title: "Solar Battery",
                        url: "/solar-battery",
                        target: "",
                    },
                ],
            },
            "main-menu-right": {
                items: [
                    {
                        title: "Impact",
                        url: "/impact",
                        target: "",
                    },
                    {
                        title: "About us",
                        url: "/about-us",
                        target: "",
                    },
                    {
                        title: "Contact",
                        url: "/contact",
                        target: "",
                    },
                ],
            },
            "mobile-menu": {
                items: [
                    {
                        title: "Solar Panels",
                        url: "/solar-panels",
                        target: "",
                    },
                    {
                        title: "Solar Battery",
                        url: "/solar-battery",
                        target: "",
                    },
                    {
                        title: "Impact",
                        url: "/impact",
                        target: "",
                    },
                    {
                        title: "About us",
                        url: "/about-us",
                        target: "",
                    },
                    {
                        title: "Contact",
                        url: "/contact",
                        target: "",
                    },
                ],
            },
        },
        "business": {
            "main-menu-left": {
                items: [],
            },
            "main-menu-right": {
                items: [],
            },
            "mobile-menu": {
                items: [],
            },
        }
    }
};

const mySamSamUrl = {
    "nl": {
        "url": "/mijn-samsam",
        "title": "Mijn SamSam"
    },
    "za": {
        "url": null, // is null so doesnt show the menu item
        "title": "My SamSam"
    },
}

const email = {
    "nl": "info@samsam.nu",
    "za": "info@samsam.co.za",
}

const whatsapp = {
    "nl": false,
    "za": "https://wa.me/+27844513574",
}

const language = {
    "nl": "nl",
    "za": "en",
}

const countryEmail = email[country] || email['nl'];
const countryWhatsapp = whatsapp[country] || whatsapp['nl'];
const countryMySamSamLink = mySamSamUrl[country] || mySamSamUrl['nl'];
const countryLanguage = language[country] || language['nl'];

function getCountryMenus(audience = 'consumer') {
    return menus[country]?.[audience] || menus['nl'][audience];
}

export { getCountryMenus, countryEmail, countryWhatsapp, countryMySamSamLink, countryLanguage };
